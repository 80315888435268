import React, { useMemo, useState } from "react";
import Head from "next/head";
import {
  Heading,
  Flex,
  Box,
  Text,
  Icon,
  Stack,
  Input,
  IconButton,
  Link as StyledLink,
  Tooltip,
} from "@chakra-ui/react";
import Link from "next/link";
import {
  MapPin,
  Mail,
  Phone,
  Facebook,
  Twitter,
  Instagram,
  Search,
  Icon as FeatherIcon,
  BarChart2,
  Star,
} from "react-feather";
import axios from "axios";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import ContactInfo from "./ContactInfo";
import StickyHeader from "./StickyHeader";
import { SearchProvider, useSearch } from "./SearchContext";

import paths from "@/utils/paths";
import { Website } from "@/types/website";
import { useStaleSWR } from "@/hooks/useStaleSWR";
import { useDebounce } from "@/hooks/useDebounce";
import { useToastPromise } from "@/hooks/useToast";
import { getSocialMediaURL } from "@/utils/socialMedia";
import UserMenu from "@/components/Layout/Header/UserMenu";
import { Image, ImageAutoWidth } from "@/components/common/Image";
import { SocialMedia as SocialMediaTypes } from "@/types/socialMedia";
import LanguageSwitcher from "@/components/Layout/Header/LanguageSwitcher";

type NavItemProp = {
  label: string;
  href?: string;
  active?: boolean;
  onClick?: () => void;
};

type LayoutProps = {
  title: string;
  children: React.ReactNode;
  website: Website;
  noHeading?: boolean;
};

const Layout = ({
  title,
  children,
  website,
  noHeading = false,
}: LayoutProps): React.ReactElement => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { pathname } = router;
  const { pageRef, isSticky } = StickyHeader();
  const isOrganization = !!website?.organization;
  const pressOffice = website?.pressOffice || website?.organization;

  if (!pressOffice) return null;

  const NavItem = ({ label, href, active = false, onClick }: NavItemProp) => (
    <Link
      passHref
      href={paths.getWebsiteHref(href, website?.slug, website?.isSubdomain)}
    >
      <Box
        as="a"
        cursor="pointer"
        px={6}
        py={4}
        _hover={{
          color: "text.100",
          bg: "light.200",
        }}
        color="main.700"
        bg={active ? "light.200" : "transparent"}
        onClick={onClick}
      >
        <Text
          textTransform="uppercase"
          letterSpacing="1px"
          fontWeight="500"
          textAlign="center"
        >
          {label}
        </Text>
      </Box>
    </Link>
  );

  const navItems: NavItemProp[] = useMemo(
    () =>
      [
        {
          label: t("press.pressInfo"),
          href: paths.website.main,
        },
        {
          label: t("press.video"),
          href: paths.website.video,
          disabled: website.disabledPages?.includes("video"),
        },
        {
          label: t("cloud.audio"),
          href: paths.website.audio,
          disabled: website.disabledPages?.includes("audio"),
        },
        {
          label: t("photos"),
          href: paths.website.photos,
          disabled: website.disabledPages?.includes("photos"),
        },
        {
          label: t("website.accreditations"),
          href: paths.website.accreditation,
          disabled: website.disabledPages?.includes("accreditations"),
        },
        ...website?.pages?.map((page) => ({
          label: page.title,
          href: `/${page.slug ?? ""}`,
        })),
        {
          label: t("website.contact"),
          href: paths.website.contact,
          disabled: website.disabledPages?.includes("contact"),
        },
      ].filter((navItem) => !navItem.disabled),
    [website]
  );

  const isActive = (path: string) =>
    pathname === path || (pathname.startsWith(path) && !path.endsWith("/"));

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <SearchProvider>
      <Head>
        <title>{title} - Accred.eu</title>
      </Head>
      <Box pt={{ base: "74px", md: "156px" }}>
        <Box position="fixed" top={0} left={0} right={0} zIndex="1001">
          <Box
            bg="main.800"
            transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
            transitionDuration=".3s"
            transitionProperty="height"
            height={{
              base: menuOpen ? "126px" : "74px",
              md: isSticky ? "50px" : "104px",
            }}
          >
            <Flex
              maxW="1100px"
              mx="auto"
              align="center"
              justify="space-between"
              py={3}
              wrap={{ base: "wrap", md: "nowrap" }}
              px={{ base: 4, md: 0 }}
            >
              <Flex
                align="center"
                w={{ base: "calc(100vw - 79px)", md: "auto" }}
              >
                {pressOffice?.picture && (
                  <Box h={{ base: "50px", md: "80px" }}>
                    <StyledLink
                      href={paths.getWebsiteHref(
                        "/",
                        website?.slug,
                        website?.isSubdomain
                      )}
                    >
                      <ImageAutoWidth
                        priority
                        src={`/uploads/${pressOffice?.picture}`}
                        layout="fill"
                        alt="Logo"
                      />
                    </StyledLink>
                  </Box>
                )}
                <Box
                  as="h1"
                  ml={{ base: 3, md: 5 }}
                  flex={{ base: 1, md: "none" }}
                  isTruncated
                  overflow={{ base: "hidden", md: "visible" }}
                >
                  <Heading
                    as="div"
                    fontSize={{ base: "sm", md: "md" }}
                    color="text.500"
                    mb="0"
                    transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                    transitionDuration=".3s"
                    transitionProperty="transform"
                    transform={{
                      base: "none",
                      md: isSticky ? "translateY(-60px)" : "none",
                    }}
                  >
                    {isOrganization
                      ? t("organization.organization")
                      : t("pressOffice.pressOffice")}
                  </Heading>
                  <Heading
                    as="div"
                    transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                    transitionDuration=".3s"
                    transitionProperty="font-size,transform"
                    fontSize={{ base: "lg", md: isSticky ? "1.25rem" : "30px" }}
                    transform={{
                      base: "none",
                      md: isSticky ? "translateY(-38px)" : "none",
                    }}
                    isTruncated
                  >
                    {pressOffice?.name}
                  </Heading>
                </Box>
              </Flex>
              <Box
                d={{ base: "block", md: "none" }}
                transform="rotate(-90deg)"
                color="light.10"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <Icon as={BarChart2} w="32px" h="32px" />
              </Box>
              <Flex
                w={{ base: "100%", md: "auto" }}
                h="30px"
                justifyContent="center"
                gap="2"
                mt={{ base: 3, md: 0 }}
                opacity={{ base: menuOpen ? 1 : 0, md: 1 }}
                transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                transitionDuration=".3s"
                transitionProperty="opacity"
              >
                <Flex
                  transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
                  transitionDuration=".3s"
                  transitionProperty="transform,max-height"
                  alignItems="center"
                  transform={{
                    base: "none",
                    md: isSticky ? "translateY(-27px)" : "none",
                  }}
                  overflow={{ base: "hidden", md: "visible" }}
                  w={{ base: "100%", md: "auto" }}
                  gap={2}
                >
                  <LanguageSwitcher
                    isWebsite={website?.isSubdomain}
                    isWebsiteWithoutSubdomain
                  />
                  <UserMenu website={website} />
                  {website.pressOffice && (
                    <SubscribeOfficeIcon
                      website={website}
                      pressOfficeId={website.pressOffice.id}
                    />
                  )}
                  <SearchInput isSticky={isSticky} />
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Box
            bg="white"
            boxShadow="0 3px 20px rgba(0,0,0,0.04)"
            h={{ base: menuOpen ? "calc(100vh - 126px)" : 0, md: "auto" }}
            overflow={{ base: menuOpen ? "auto" : "hidden", md: "visible" }}
            transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
            transitionDuration=".3s"
            transitionProperty="height"
          >
            <Flex
              maxW="1100px"
              mx="auto"
              align={{ base: "stretch", md: "center" }}
              justify="center"
              direction={{ base: "column", md: "row" }}
            >
              {navItems &&
                navItems.map((navItem, index) => (
                  <NavItem
                    key={index}
                    label={navItem.label}
                    href={navItem.href}
                    active={isActive(navItem.href)}
                    onClick={() => setMenuOpen(false)}
                  />
                ))}
            </Flex>
          </Box>
        </Box>
        <Box ref={pageRef}>
          <Box
            maxW="1100px"
            mx="auto"
            py={6}
            minHeight="calc(100vh - 469px)"
            px={{ base: 6, md: 0 }}
          >
            {title && !noHeading && (
              <Heading as="h2" size="lg">
                {title}
              </Heading>
            )}
            <Box color="black">{children}</Box>
          </Box>
          <Box bg="dark.800" py="30px" mt="100px" minH="212px">
            <Box maxW="1100px" mx="auto" py={6} px={{ base: 6, md: 0 }}>
              <Flex
                justify="space-between"
                align={{ base: "flex-start", md: "center" }}
                direction={{ base: "column", md: "row" }}
              >
                <Box color="text.200">
                  <Heading size="sm">{pressOffice?.name}</Heading>
                  {pressOffice.address && pressOffice.city && (
                    <ContactInfo
                      ico={MapPin}
                      value={`${pressOffice.address || ""}, ${
                        pressOffice.postalCode || ""
                      } ${pressOffice.city || ""}`}
                    />
                  )}
                  {pressOffice.email && (
                    <ContactInfo ico={Mail} value={pressOffice.email} />
                  )}
                  {pressOffice.phone && (
                    <ContactInfo ico={Phone} value={pressOffice.phone} />
                  )}
                </Box>
                <Stack direction="row" spacing="10px" mt={{ base: 6, md: 0 }}>
                  {pressOffice.facebook && (
                    <SocialMedia
                      ico={Facebook}
                      href={getSocialMediaURL(
                        pressOffice.facebook,
                        SocialMediaTypes.FACEBOOK
                      )}
                    />
                  )}
                  {pressOffice.twitter && (
                    <SocialMedia
                      ico={Twitter}
                      href={getSocialMediaURL(
                        pressOffice.twitter,
                        SocialMediaTypes.TWITTER
                      )}
                    />
                  )}
                  {pressOffice.instagram && (
                    <SocialMedia
                      ico={Instagram}
                      href={getSocialMediaURL(
                        pressOffice.instagram,
                        SocialMediaTypes.INSTAGRAM
                      )}
                    />
                  )}
                </Stack>
              </Flex>
              <Flex align="center" justify="flex-end" mt="50px">
                <Text color="gray.200" mr={2} fontSize="xs">
                  {t("poweredBy")}
                </Text>
                <StyledLink href="https://accred.eu" isExternal>
                  <Box width="100px" height="25px" position="relative">
                    <Image
                      priority
                      src="/logo.svg"
                      objectFit="contain"
                      objectPosition="left"
                      alt=""
                    />
                  </Box>
                </StyledLink>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </SearchProvider>
  );
};

type SubscribeOfficeIconProps = {
  pressOfficeId: number;
  website: Website;
};

const SubscribeOfficeIcon: React.FC<SubscribeOfficeIconProps> = ({
  pressOfficeId,
  website,
}: SubscribeOfficeIconProps) => {
  const router = useRouter();
  const toast = useToastPromise();
  const { t } = useTranslation("common");
  const { data: pressOffice, mutate } = useStaleSWR(
    pressOfficeId ? `/press-office/${pressOfficeId}` : null
  );

  const handleClick = () => {
    if (pressOffice?.subscribed) {
      return toast.promise(
        axios
          .post(`/press-office/${pressOfficeId}/subscription`, {
            status: "unsubscribe",
          })
          .then(mutate)
      );
    }

    const redirectUrl = paths.getAppHref(
      `/offices/office/${pressOfficeId}?subscribe=true`,
      website?.isSubdomain
    );

    router.push({
      pathname: paths.getWebsiteHref(
        "/signin",
        website?.slug,
        website?.isSubdomain
      ),
      query: { to: redirectUrl },
    });
  };

  return (
    <Box
      lineHeight="100%"
      color="text.100"
      cursor="pointer"
      onClick={handleClick}
    >
      <Tooltip
        hasArrow
        label={
          pressOffice?.subscribed
            ? t("website.unsubscribe")
            : t("website.subscribe")
        }
        placement="top"
      >
        <Icon
          fill={pressOffice?.subscribed ? "currentcolor" : "none"}
          as={Star}
          w="20px"
          h="20px"
          alignItems="center"
        />
      </Tooltip>
    </Box>
  );
};

type SocialMediaProps = {
  ico: FeatherIcon;
  href: string;
};

const SocialMedia = ({ ico, href }: SocialMediaProps) => (
  <Flex
    as="a"
    href={href}
    bg="main.800"
    color="light.10"
    w="45px"
    h="45px"
    align="center"
    justify="center"
    _hover={{
      bg: "main.700",
    }}
  >
    <Icon as={ico} w="20px" h="20px" />
  </Flex>
);

type SearchInputProps = {
  isSticky: boolean;
};

const SearchInput: React.FC<SearchInputProps> = ({
  isSticky,
}: SearchInputProps) => {
  const { setSearchTerm } = useSearch();
  const { t } = useTranslation("common");
  const [searchValue, setSearchValue] = React.useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  React.useEffect(() => {
    setSearchTerm(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <Flex ml={2} minW="240px">
      <Input
        transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
        transitionDuration=".3s"
        transitionProperty="all"
        variant="header"
        flex={1}
        placeholder={`${t("search")}...`}
        size={isSticky ? "sm" : "md"}
        pl="1rem"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <IconButton
        transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
        transitionDuration=".3s"
        transitionProperty="all"
        variant="header"
        aria-label={t("search")}
        icon={<Search />}
        px=".25rem"
        size={isSticky ? "sm" : "md"}
        w="40px"
      />
    </Flex>
  );
};

export default Layout;
