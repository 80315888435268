import {
  FACEBOOK_URL_REGEX,
  INSTRAGRAM_URL_REGEX,
  LINKEDIN_REGEX,
  SocialMedia,
  SocialMediaNickName,
  TIKTOK_URL_REGEX,
  TWITCH_URL_REGEX,
  TWITTER_URL_REGEX,
  URL_REGEX,
  YOUTUBE_URL_REGEX,
} from "@/types/socialMedia";

export const getSocialMediaNickname = (
  socialMediaURL: string
): SocialMediaNickName => {
  if (!socialMediaURL) return { error: false };
  if (!socialMediaURL.match(URL_REGEX))
    return { nick: socialMediaURL, error: false };

  if (socialMediaURL.match(INSTRAGRAM_URL_REGEX)) {
    const instagramNick = socialMediaURL.match(INSTRAGRAM_URL_REGEX);
    return { nick: instagramNick[1], error: false };
  }

  if (socialMediaURL.match(FACEBOOK_URL_REGEX)) {
    const facebookNick = socialMediaURL.match(FACEBOOK_URL_REGEX);
    return { nick: facebookNick[1], error: false };
  }

  if (socialMediaURL.match(TWITTER_URL_REGEX)) {
    const twitterNick = socialMediaURL.match(TWITTER_URL_REGEX);
    return { nick: twitterNick[3], error: false };
  }

  if (socialMediaURL.match(YOUTUBE_URL_REGEX)) {
    const youtubeNick = socialMediaURL.match(YOUTUBE_URL_REGEX);
    return { nick: youtubeNick[1], error: false };
  }

  if (socialMediaURL.match(TWITCH_URL_REGEX)) {
    const twitchNick = socialMediaURL.match(TWITCH_URL_REGEX);
    return { nick: twitchNick[1], error: false };
  }

  if (socialMediaURL.match(LINKEDIN_REGEX)) {
    const linkedinNick = socialMediaURL.match(LINKEDIN_REGEX);
    return { nick: linkedinNick[9], error: false };
  }

  if (socialMediaURL.match(TIKTOK_URL_REGEX)) {
    const tiktokNick = socialMediaURL.match(TIKTOK_URL_REGEX);
    return { nick: tiktokNick[1], error: false };
  }

  return {
    error: true,
  };
};

export const getSocialMediaURL = (
  socialMediaNickname: string,
  socialMedia: SocialMedia
): string => {
  if (socialMedia === SocialMedia.FACEBOOK)
    return `https://www.facebook.com/${socialMediaNickname}/`;

  if (socialMedia === SocialMedia.INSTAGRAM)
    return `https://www.instagram.com/${socialMediaNickname}/`;

  if (socialMedia === SocialMedia.TWITTER)
    return `https://www.twitter.com/${socialMediaNickname}/`;

  if (socialMedia === SocialMedia.YOUTUBE)
    return `https://www.youtube.com/${socialMediaNickname}/`;

  if (socialMedia === SocialMedia.TWITCH)
    return `https://www.twitch.tv/${socialMediaNickname}/`;

  if (socialMedia === SocialMedia.LINKEDIN)
    return `https://www.linkedin.com/in/${socialMediaNickname}`;

  if (socialMedia === SocialMedia.TIKTOK)
    return `https://www.tiktok.com/@${socialMediaNickname}`;

  if (socialMedia === SocialMedia.OTHER) return socialMediaNickname;

  return null;
};
