import React from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";

type Props = {
  ico: any;
  value: string;
};

const ContactInfo = ({ ico, value }: Props): React.ReactElement => {
  if (!value) return null;
  return (
    <Flex mt={2} wrap={{ base: "wrap", md: "nowrap" }}>
      <Icon as={ico} mr={3} mt="3px" />
      <Text whiteSpace="pre-wrap">{value}</Text>
    </Flex>
  );
};

export default ContactInfo;
