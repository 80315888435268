export enum SocialMedia {
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  TWITTER = "twitter",
  YOUTUBE = "youtube",
  TWITCH = "twitch",
  LINKEDIN = "linkedin",
  TIKTOK = "tiktok",
  OTHER = "other",
}

export type SocialMediaNickName = {
  nick?: string;
  error: boolean;
};

export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const FACEBOOK_URL_REGEX = `/(?:(?:http|https)://)?(?:www.)?(?:facebook.com)/([A-Za-z0-9-_.]+)`;

export const TWITTER_URL_REGEX = `^https?://(www\.)?twitter\.com/(#!/)?([^/]+)(/\w+)*$`;

export const INSTRAGRAM_URL_REGEX = `/(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/`;

export const YOUTUBE_URL_REGEX = `(?:https|http)\:\/\/(?:[\w]+\.)?youtube\.com\/(?:c\/|channel\/|user\/)?([a-zA-Z0-9\-]{1,})`;

export const TWITCH_URL_REGEX = `/^[(http|https)://www.twitch.tv/]+((#)?[a-zA-Z0-9][\w]{2,24})$/U`;

export const LINKEDIN_REGEX =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

export const TIKTOK_URL_REGEX = `^https://www\.tiktok\.com/@([A-Za-z0-9-_.]+)$`;
